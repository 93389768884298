import { useCallback, useEffect, useRef, useState } from "react";
import CarsayoHeader from "../layout/header";
import CarsayoFooter from "../layout/footer";
import CarsayoTopButton from "../components/CarsayoTopButton";
import { motion, useAnimation } from "framer-motion";
import React from "react";
import { debounce } from "lodash";
import { useLocation, useSearchParams } from "react-router-dom";

interface CarsayoContentWrapInterface {
  children: React.ReactNode;
  isMobile: boolean;
  isPopupOpen?: boolean;
}

const CarsayoContentWrap = ({
  children,
  isMobile,
  isPopupOpen,
}: CarsayoContentWrapInterface) => {
  const outerDivRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageRef = useRef<number>(1);

  const scrollingRef = useRef<boolean>(false);
  const controls = useAnimation();

  const [searchParams, setSearchParams] = useSearchParams();

  const scrollToPosition = (targetPos: number, duration: any) => {
    if (!outerDivRef.current) return;
    const start = outerDivRef.current.scrollTop;
    const change = targetPos - start;
    const startTime = performance.now();

    const animateScroll = (currentTime: any) => {
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1); // 0 ~ 1
      if (outerDivRef.current) {
        outerDivRef.current.scrollTop = start + change * progress;
      }

      if (timeElapsed < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  const moveTo = {
    top: () => {
      setCurrentPage(1);
      pageRef.current = 1;
      outerDivRef.current?.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    prev: () => {
      if (!outerDivRef.current) return;

      const pageHeight = window.innerHeight;

      if (pageRef.current > 1) {
        const prevPage = pageRef.current - 1;
        setCurrentPage(prevPage);
        pageRef.current = prevPage;
        outerDivRef.current.scrollTo({
          top: pageHeight * (prevPage - 1),
          left: 0,
          behavior: "smooth",
        });
        scrollingRef.current = false;
        // scrollToPosition(pageHeight * (prevPage - 1), 300); // 1000ms = 1초 동안 이동
        // outerDivRef.current.addEventListener(
        //   "scrollend",
        //   () => {
        //     scrollingRef.current = false;
        //   },
        //   {
        //     once: true,
        //   }
        // );
      }
    },
    next: () => {
      if (!outerDivRef.current) return;

      const pageHeight = window.innerHeight;
      const totalHeight = outerDivRef.current?.scrollHeight ?? 0;
      const totalPages = Math.ceil(totalHeight / pageHeight);

      if (pageRef.current < totalPages) {
        const nextPage = pageRef.current + 1;
        setCurrentPage(nextPage);
        pageRef.current = nextPage;
        outerDivRef.current.scrollTo({
          top: pageHeight * (nextPage - 1),
          left: 0,
          behavior: "smooth",
        });
        scrollingRef.current = false;
        // scrollToPosition(pageHeight * (nextPage - 1), 300); // 1000ms = 1초 동안 이동

        // outerDivRef.current.addEventListener(
        //   "scrollend",
        //   () => {
        //     scrollingRef.current = false;
        //   },
        //   {
        //     once: true,
        //   }
        // );
      }
    },
  };

  const wheelHandler = useCallback(
    debounce(
      (e: WheelEvent) => {
        e.preventDefault();
        if (!outerDivRef.current) return;
        const { deltaY } = e;
        scrollingRef.current = true;
        if (deltaY > 0) {
          moveTo.next();
        } else {
          moveTo.prev();
        }
      },
      300
      // { leading: true, trailing: false }
    ),
    []
  );
  const keydownHandler = useCallback(
    debounce(
      (e: KeyboardEvent) => {
        e.preventDefault();

        if (!outerDivRef.current) return;

        const { key } = e;

        switch (key) {
          case "ArrowUp":
            moveTo.prev();
            break;
          case "ArrowDown":
            moveTo.next();
            break;
          default:
            break;
        }
      },
      300,
      { leading: true, trailing: false }
    ),
    []
  );

  useEffect(() => {
    if (!wheelHandler) return;
    if (scrollingRef.current) return;
    const outerDivRefCurrent = outerDivRef.current;
    if (outerDivRefCurrent) {
      outerDivRefCurrent.addEventListener("wheel", wheelHandler);

      document.addEventListener("keydown", keydownHandler, {
        passive: false,
      });
    }
    return () => {
      if (outerDivRefCurrent) {
        outerDivRefCurrent.removeEventListener("wheel", wheelHandler);
        document.removeEventListener("keydown", keydownHandler);
      }
    };
  }, [wheelHandler]);

  useEffect(() => {
    if (currentPage > 1) {
      controls.start({ y: -150 }); // 헤더 숨기기
    } else {
      controls.start({ y: 0 }); // 헤더 보이기
    }
  }, [currentPage, controls]);

  /** 특정 링크(네이버 브랜드 광고 등)로 접근 시 처음 스크롤 위치를 설정합니다. */
  useEffect(() => {
    if (!outerDivRef.current) return;

    const naverQueryString = searchParams.get("utm_campagin");
    const targetPage: number | null = naverQueryString?.includes("subimage1")
      ? 3
      : naverQueryString?.includes("subimage2")
      ? 8
      : naverQueryString?.includes("subimage3")
      ? 7
      : null;

    if (targetPage === null) return;

    const pageHeight = window.innerHeight;

    setCurrentPage(targetPage);
    pageRef.current = targetPage;
    outerDivRef.current.scrollTo({
      top: pageHeight * (targetPage - 1),
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="main">
      <motion.header
        animate={controls}
        transition={{ delay: 0.1, duration: 0.3 }}
        style={{
          position: "fixed",
          top: 0,
          zIndex: 1000,
        }}
      >
        <CarsayoHeader currentPage={currentPage} />
      </motion.header>
      <div className="content" ref={isPopupOpen ? null : outerDivRef}>
        {children}
        <CarsayoFooter />
      </div>
      {isMobile === false && (
        <CarsayoTopButton
          goTop={moveTo.top}
          disabled={currentPage > 2 ? false : true}
          style={{
            opacity: `${currentPage > 2 ? "1" : "0"}`,
            transition: "opacity .3s ease",
            cursor: `${currentPage > 2 ? "pointer" : "default"}`,
          }}
        />
      )}
    </div>
  );
};
export default CarsayoContentWrap;
