import { motion } from "framer-motion";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import { useState } from "react";
import TypingTextAnimation from "../../../utils/typingTextAnimation";
import UnderlineSketch from "../../components/svg/UnderlineSketch";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";

const Character01 = () => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <section>
      <div className="character__section01">
        <div>
          <div className="left">
            <div className="left__title">
              <VerticalMoveAnimation
                element="div"
                duration={0.3}
                direction="top"
                setAnimated={setAnimated}
              >
                <h3>
                  <span style={{ fontFamily: "Times New Roman" }}>Car</span>
                  <span style={{ fontFamily: "Times New Roman" }}>
                    Heroes Rise
                  </span>
                </h3>
                <span className={isAnimated ? "animation" : ""}>
                  <UnderlineSketch fill="#FFE492" />
                </span>
                <span
                  className={
                    isAnimated ? "animation star-effect" : "star-effect"
                  }
                >
                  <img src="../imgs/svg/star-effect.svg" alt="별모양" />
                </span>
              </VerticalMoveAnimation>
            </div>
            <div className="left__contents">
              <HorizontalMoveAnimation
                element="div"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <span>
                  그리스에 머물던 카리테스의 세 여신은 사람들에게 헹복을
                  가져다주는 힘을 지니고 있었다.
                </span>
                <span>
                  이들은 그리스에서 자기 할일을 마치고 카스토르 별에 가서 행복한
                  안식을 누리고 있었다.
                </span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.3}
                duration={0.5}
                direction="left"
              >
                <span>
                  이들의 취미는 쌍둥이 엔진 자동차로 드라이브를 하는 것과
                  지구에서 그리스와 비슷한 성향의 나라를
                </span>
                <span>
                  찾는 것이었는데, 그들이 찾은 곳은 바로 K-POP으로 전 세계
                  문화에 지대한 영향을 끼치고 있는
                </span>
                <span>대한민국이었다.</span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.3}
                duration={0.5}
                direction="left"
              >
                <span>
                  이들은 대한민국에서 들리는 사운드에 이끌려 세모 모양의 쌍둥이
                  엔진 수퍼카 CARSAYO를 타고
                </span>
                <span>
                  대한민국으로 오게 되는데.. CARSAYO는 빛의 속도로 이동해서
                  지구로 올때까지 52년이 걸렸다.
                </span>
                <span>
                  누군가 자기들을 알아볼까 싶어 이름도 외모도 조금씩 바꾼 채....
                </span>
              </HorizontalMoveAnimation>
            </div>
          </div>
          <div
            className="right"
            // style={{
            //   backgroundImage: 'url("../imgs/svg/character-section01-bg.svg")',
            // }}
          >
            <img src="../imgs/svg/character-section01-bg.svg" alt="" />
            <div className="right__symbol">
              <img
                src="../imgs/svg/character-section01-symbol.svg"
                alt="카사요 심볼로고"
              />
            </div>
            <div className="right__character">
              <img
                src="../imgs/svg/character-section01.svg"
                alt="카사요 히어로즈"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Character01;
