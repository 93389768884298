import { motion } from "framer-motion";

interface OverviewProgress {
  currentPage: number;
  goMove: (page: number) => void;
}

const CharacterProgressBox = ({ currentPage, goMove }: OverviewProgress) => {
  return (
    <ul
      className="character__progress"
      style={{
        opacity: `${currentPage > 1 && currentPage < 5 ? "1" : "0"}`,
        transition: "opacity .3s ease",
      }}
    >
      <li
        style={{
          height: currentPage === 2 ? "20px" : "4px",
          backgroundColor: currentPage === 4 ? "#F8F8FD" : "#F8D3EB",
          transition: "all .3s ease",
        }}
      ></li>
      <li
        style={{
          height: currentPage === 3 ? "20px" : "4px",
          backgroundColor: currentPage === 4 ? "#F8F8FD" : "#F8D3EB",
          transition: "all .3s ease",
        }}
      ></li>
      <li
        style={{
          height: currentPage === 4 ? "20px" : "4px",
          backgroundColor: currentPage === 4 ? "#F8F8FD" : "#F8D3EB",
          transition: "all .3s ease",
        }}
      ></li>
      <li
        style={{
          height: currentPage === 5 ? "20px" : "4px",
          backgroundColor: currentPage === 4 ? "#F8F8FD" : "#F8D3EB",
          transition: "all .3s ease",
        }}
      ></li>
    </ul>
  );
};
export default CharacterProgressBox;
