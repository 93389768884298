import { motion, useAnimation } from "framer-motion";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import CarsayoContentWrap from "../layout/content";
import CarsayoMobileWrap from "../layout/mobileWrap";
import OSCheck from "../components/OSCheck";
import PremiumEvent from "./PremiumEvent";
import CarsayoPromotionPopup from "../components/CarsayoPromotionPopup";
import CarsayoFileUpload from "../components/CarsayoFileUpload";
import PremiumEventMobile from "./PremiumEventMobile";

const PromotionPage = () => {
  const currentOS = OSCheck();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isPopupOpen, setPopupOpen] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {currentOS === "Android" || currentOS === "iOS" ? (
        <CarsayoMobileWrap hiddenHeader={true}>
          <PremiumEventMobile />
        </CarsayoMobileWrap>
      ) : isMobile ? (
        <CarsayoMobileWrap hiddenHeader={true}>
          <PremiumEventMobile />
        </CarsayoMobileWrap>
      ) : (
        <CarsayoContentWrap isPopupOpen={isPopupOpen} isMobile={isMobile}>
          <PremiumEvent setPopup={() => setPopupOpen(!isPopupOpen)} />
          <CarsayoPromotionPopup
            isShown={isPopupOpen}
            onClose={() => setPopupOpen(false)}
            title={"카사요 만원의 견적(리스, 장기렌트 한정)"}
            titleImgUrl="../imgs/svg/premium-bg.svg"
          >
            <div style={{ height: "800px" }}>
              팝업내용
              <CarsayoFileUpload label={"파일 업로드"} />
            </div>
          </CarsayoPromotionPopup>
        </CarsayoContentWrap>
      )}
    </>
  );
};
export default PromotionPage;
