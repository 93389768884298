import { motion, useAnimation } from "framer-motion";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import CarsayoTopButton from "../../components/CarsayoTopButton";
import CarsayoFooter from "../../layout/footer";
import CarsayoHeader from "../../layout/header";
import { debounce } from "lodash";
import Character01 from "./section01";
import CharacterProgressBox from "./progress";
import Character02 from "./section02";
import Character03 from "./section03";
import Character04 from "./section04";
import Character05 from "./section05";
import { useNavigate } from "react-router-dom";

interface CarsayoOCOverviewInterface {
  isMobile: boolean;
}

const CarsayoPCCharacter = ({ isMobile }: CarsayoOCOverviewInterface) => {
  const navigate = useNavigate();
  const pageRef = useRef<number>(1);
  const scrollingRef = useRef<boolean>(false);
  const outerDivRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const controls = useAnimation();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [keyId, setKeyId] = useState("");
  const handleClickEvent = {
    move: (page: number) => {
      const pageHeight = window.innerHeight;
      setCurrentPage(page);
      pageRef.current = page;
      scrollingRef.current = true;
      outerDivRef.current?.scrollTo({
        top: pageHeight * (page - 1),
        left: 0,
        behavior: "smooth",
      });
      outerDivRef.current?.addEventListener(
        "scrollend",
        () => {
          scrollingRef.current = false;
        },
        {
          once: true,
        }
      );
    },
  };

  const handleMouseEnter = (index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const moveTo = {
    top: () => {
      setCurrentPage(1);
      outerDivRef.current?.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    prev: () => {
      if (!outerDivRef.current) return;

      const pageHeight = window.innerHeight;

      if (pageRef.current > 1) {
        const prevPage = pageRef.current - 1;
        setCurrentPage(prevPage);
        pageRef.current = prevPage;
        scrollingRef.current = true;
        outerDivRef.current.scrollTo({
          top: pageHeight * (prevPage - 1),
          left: 0,
          behavior: "smooth",
        });
        outerDivRef.current.addEventListener(
          "scrollend",
          () => {
            scrollingRef.current = false;
          },
          {
            once: true,
          }
        );
      }
    },
    next: () => {
      if (!outerDivRef.current) return;

      const pageHeight = window.innerHeight;
      const totalHeight = outerDivRef.current?.scrollHeight ?? 0;
      const totalPages = Math.ceil(totalHeight / pageHeight);

      if (pageRef.current < totalPages) {
        const nextPage = pageRef.current + 1;
        setCurrentPage(nextPage);
        pageRef.current = nextPage;
        scrollingRef.current = true;
        outerDivRef.current.scrollTo({
          top: pageHeight * (nextPage - 1),
          left: 0,
          behavior: "smooth",
        });

        outerDivRef.current.addEventListener(
          "scrollend",
          () => {
            scrollingRef.current = false;
          },
          {
            once: true,
          }
        );
      }
    },
  };

  const wheelHandler = useCallback(
    debounce(
      (e: WheelEvent) => {
        e.preventDefault();
        if (!outerDivRef.current) return;

        const { deltaY } = e;

        if (deltaY > 0) {
          moveTo.next();
        } else {
          moveTo.prev();
        }
      },
      300,
      { leading: true, trailing: false }
    ),
    []
  );
  const keydownHandler = useCallback(
    debounce(
      (e: KeyboardEvent) => {
        e.preventDefault();
        if (!outerDivRef.current) return;

        const { key } = e;

        switch (key) {
          case "ArrowUp":
            moveTo.prev();
            break;
          case "ArrowDown":
            moveTo.next();
            break;
          default:
            break;
        }
      },
      300,
      { leading: true, trailing: false }
    ),
    []
  );

  useLayoutEffect(() => {
    const goBackHome = () => {
      setPopupOpen(false);
    };
    if (isPopupOpen) {
      window.history.pushState(null, "", window.location.pathname);
    }
    window.addEventListener("popstate", goBackHome);

    return () => window.removeEventListener("popstate", goBackHome);
  }, [navigate, isPopupOpen]);

  useEffect(() => {
    if (!wheelHandler) return;
    const outerDivRefCurrent = outerDivRef.current;
    if (outerDivRefCurrent) {
      outerDivRefCurrent.addEventListener("wheel", wheelHandler, {
        passive: false,
      });

      document.addEventListener("keydown", keydownHandler, {
        passive: false,
      });
    }
    return () => {
      if (outerDivRefCurrent) {
        outerDivRefCurrent.removeEventListener("wheel", wheelHandler);
        document.removeEventListener("keydown", keydownHandler);
      }
    };
  }, [wheelHandler, keydownHandler]);

  useEffect(() => {
    if (currentPage > 1) {
      controls.start({ y: -150 }); // 헤더 숨기기
    } else {
      controls.start({ y: 0 }); // 헤더 보이기
    }
  }, [currentPage, controls]);
  return (
    <div className="main">
      <motion.header
        animate={controls}
        transition={{ delay: 0.1, duration: 0.3 }}
        style={{
          position: "fixed",
          top: 0,
          zIndex: 1000,
        }}
      >
        <CarsayoHeader currentPage={currentPage} />
      </motion.header>
      <div className="content" ref={isPopupOpen ? null : outerDivRef}>
        <Character01 />
        <Character02
          keyId={keyId}
          isPopupOpen={isPopupOpen}
          setPopupOpen={() => {
            setPopupOpen(!isPopupOpen);
            setKeyId("section02");
          }}
        />
        <Character03
          keyId={keyId}
          isPopupOpen={isPopupOpen}
          setPopupOpen={() => {
            setPopupOpen(!isPopupOpen);
            setKeyId("section03");
          }}
        />
        <Character04
          keyId={keyId}
          isPopupOpen={isPopupOpen}
          setPopupOpen={() => {
            setPopupOpen(!isPopupOpen);
            setKeyId("section04");
          }}
        />
        <Character05 />
        <CharacterProgressBox
          currentPage={currentPage}
          goMove={handleClickEvent.move}
        />
        <CarsayoFooter />
      </div>
      {isMobile === false && (
        <CarsayoTopButton
          goTop={moveTo.top}
          disabled={currentPage > 2 ? false : true}
          style={{
            opacity: `${currentPage > 2 ? "1" : "0"}`,
            transition: "opacity .3s ease !important",
            cursor: `${currentPage > 2 ? "pointer" : "default"}`,
          }}
        />
      )}
    </div>
  );
};
export default CarsayoPCCharacter;
