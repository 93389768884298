interface ExpressionInterface {
  textColor?: string;
  text?: string;
  width?: string;
  height?: string;
  fill?: string;
  clickEvent: () => void;
  className?: string | "mb";
}

const Expression = ({
  textColor,
  text,
  width,
  height,
  fill,
  clickEvent,
  className,
}: ExpressionInterface) => {
  return (
    <button
      className={`character__section__expression-button ${className}`}
      onClick={clickEvent}
      style={{
        backgroundImage: "none",
        width: width ? `${Number(width)}px` : "auto",
        height: height ? `${Number(height)}px` : "auto",
        maxWidth: className?.includes("mb") ? "auto" : "12.5rem",
        maxHeight: className?.includes("mb") ? "auto" : "5.625rem",
      }}
    >
      <span style={{ color: textColor ?? "#fff" }}>{text ?? "Expression"}</span>
      <svg
        width={width || "161"}
        height={height || "81"}
        viewBox={`0 0 ${width || 161} ${height || 81}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          maxWidth: className?.includes("mb") ? "auto" : "12.5rem",
          maxHeight: className?.includes("mb") ? "auto" : "5.625rem",
        }}
      >
        <path
          d="M7.68786 20.1081C8.83563 13.3541 14.4748 8.27455 21.3116 7.83638L132.489 0.710935C139.059 0.289884 145.115 4.2652 147.341 10.4603L159.738 44.9529C162.945 53.8779 157.035 63.4712 147.621 64.62L16.6807 80.5984C6.86488 81.7962 -1.335 73.2024 0.321718 63.4536L7.68786 20.1081Z"
          fill={fill || "#6365D9"}
          transform={`scale(${width ? Number(width) / 161 : 1}, ${
            height ? Number(height) / 81 : 1
          })`}
        />
      </svg>
    </button>
  );
};
export default Expression;
