import { useNavigate } from "react-router-dom";
import Expression from "../components/svg/Expression";
import { useEffect, useState } from "react";

interface PremiumEventInterface {
  setPopup: () => void;
}

const PremiumEvent = ({ setPopup }: PremiumEventInterface) => {
  const navigate = useNavigate();
  const [iframeLocation, setIframeLocation] = useState(
    "https://app.carsayo.net"
  );

  useEffect(() => {
    console.info("iframe 확인용   ----   ", iframeLocation);
  }, [iframeLocation]);
  return (
    <section>
      <div className="premiumEvent">
        <div>
          {/* <div className="left">
            <div className="premiumEvent__box">
              <img
                src="../imgs/svg/premium-character01.svg"
                alt="카키돋보기이미지"
              />
              <div className="premiumEvent__box__content">
                <div className="content__01">
                  <div
                    className="content__01__bg"
                    style={{
                      backgroundImage: "url(../imgs/svg/premium-bg.svg)",
                    }}
                  ></div>
                  <h3>만원으로 내 차가 달라져요</h3>
                  <p>
                    <span>타사 견적을 알아보셨나요?</span>
                    <span>첨부한 타사 견적서가 카사요 견적보다 낮을 경우</span>
                    <span>
                      만원을 더해 <strong>2만원</strong>을 돌려드립니다
                    </span>
                  </p>
                  <ul>
                    <li>
                      <div>
                        <img
                          src="../imgs/svg/checkbox-circle-fill.svg"
                          alt="체크이미지"
                        />
                        <span>견적유형</span>
                      </div>
                      <p>리스 또는 장기렌트</p>
                    </li>
                    <li>
                      <div>
                        <img
                          src="../imgs/svg/checkbox-circle-fill.svg"
                          alt="체크이미지"
                        />
                        <span>참여 금액</span>
                      </div>
                      <p>만원</p>
                    </li>
                    <li>
                      <div>
                        <img
                          src="../imgs/svg/checkbox-circle-fill.svg"
                          alt="체크이미지"
                        />
                        <span>참여 조건</span>
                      </div>
                      <p>타사 견적서 첨부</p>
                    </li>
                  </ul>
                  <button onClick={setPopup}>상세보기</button>
                </div>
                <div className="content__02">
                  <h4>카사요는 왜 다른가요?</h4>
                  <div>
                    <ul>
                      <li>
                        <div>
                          <img
                            src="../imgs/svg/collaborate-icon.svg"
                            alt="제휴아이콘"
                          />
                        </div>
                        <h5>
                          <span>카매니저님들과는</span>
                          <span>제휴가 아닌</span>
                        </h5>
                        <p>
                          <span>
                            26년 현장 경험을 바탕으로 국내 최대 네트워크
                            인프라를 활용해서 안전해요.
                          </span>
                        </p>
                      </li>
                      <li>
                        <div>
                          <img
                            src="../imgs/svg/coin-icon.svg"
                            alt="동전아이콘"
                          />
                        </div>
                        <h5>
                          <span>자동차 금융상품을</span>
                          <span>직접 취급</span>
                        </h5>
                        <p>
                          <span>
                            할 수 있어서 저렴한 견적을 제공할 수 있어요.
                          </span>
                        </p>
                      </li>
                      <li>
                        <div>
                          <img
                            src="../imgs/svg/mark-icon.svg"
                            alt="인증마크아이콘"
                          />
                        </div>
                        <h5>
                          <span>ESG경영, 벤처기업인증,</span>
                          <span>기업부설연구소, 특허 등</span>
                        </h5>
                        <p>
                          <span>
                            많은 노력을 하는 신뢰할 수 있는 플랫폼이에요
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="left">
            <div className="promotion-contents">
              <div className="promotion-contents__box">
                <strong>premium service</strong>
                <h3>
                  <span>CAR</span>
                  <span>HEROES RISE</span>
                </h3>
                <p>
                  <span>견적받기부터 시작되는 복잡한 차량 구매 절차</span>
                  <span>결제 방식에 따른 이자 계산, 보험, 사후관리까지 </span>
                  <span>더 이상 두고 볼수만은 없기에</span>
                  <span>
                    자신만의 전문성을 살려 최고의 서비스를 제공하는 카사요
                    히어로즈를 만나보세요
                  </span>
                </p>
                <Expression
                  width="169"
                  height="83"
                  fill="#FF5B79"
                  text="히어로즈 알아보기"
                  clickEvent={() => navigate("/company/character")}
                />
              </div>
              <nav className="promotion-contents__nav">
                <ul>
                  <li
                    style={{
                      backgroundImage:
                        "url(../imgs/svg/promotion-menu-bg02.svg)",
                    }}
                    onClick={() => {
                      setIframeLocation("https://app.carsayo.net");
                      setTimeout(() => {
                        setIframeLocation(
                          "https://app.carsayo.net/static/insurance"
                        );
                      }, 0);
                    }}
                  >
                    <button>#1. 다이렉트 보험</button>
                  </li>
                  <li
                    style={{
                      backgroundImage:
                        "url(../imgs/svg/promotion-menu-bg01.svg)",
                    }}
                  >
                    <button>#2. Comming Soon</button>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="promotion-decoration">
              <img
                src="../imgs/png/decoration01.png"
                alt="프로모션 데코이미지01"
              />
              <img
                src="../imgs/png/decoration02.png"
                alt="프로모션 데코이미지02"
              />
              <img
                src="../imgs/svg/character_section05_img03.svg"
                alt="카사요히어로즈 이미지"
              />
            </div>
          </div>
          <div className="right">
            <iframe
              src={iframeLocation}
              width="100%"
              height="100%"
              style={{
                border: "none",
                overflowY: "hidden",
                scrollbarWidth: "none",
              }}
              title="Carasyo App"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PremiumEvent;
