import { motion } from "framer-motion";
import VerticalMoveAnimation from "../../utils/verticalMoveAnimation";
import { useState } from "react";
import HorizontalMoveAnimation from "../../utils/horizontalMoveAnimation";
import React from "react";
import UnderlineSketch from "../components/svg/UnderlineSketch";
import { Link } from "react-router-dom";

const CarsayoVisualSection = () => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <section>
      <div className="wrap">
        <div className="main-visual">
          <div className="left">
            <VerticalMoveAnimation
              element="div"
              duration={0.5}
              direction="top"
              setAnimated={setAnimated}
              className="character-area"
              style={{
                backgroundImage:
                  "url(../imgs/svg/main-section01-character.svg)",
              }}
            >
              <div className="character-title">
                <h3>With Car Heroes</h3>
                <span className={isAnimated ? "animation" : ""}>
                  <UnderlineSketch fill="#FFE492" width="343" />
                </span>
              </div>
              <Link to={"/company/character"}>캐릭터 스토리 보기</Link>
            </VerticalMoveAnimation>
            <VerticalMoveAnimation
              element="div"
              delay={0.2}
              duration={0.5}
              direction="top"
              setAnimated={setAnimated}
            >
              <h3>
                <p>자동차의 모든 것</p>
                <p>
                  <span>카사요에서 쉽고</span> <span>간편하게</span>
                </p>
              </h3>
            </VerticalMoveAnimation>
            <VerticalMoveAnimation
              element="p"
              delay={0.4}
              duration={0.5}
              direction="top"
              setAnimated={setAnimated}
            >
              <span>
                자동차 관련 모든 서비스를 제공하는 국내에서 유일한 자동차 쇼핑
                통합 플랫폼입니다.
              </span>
              <span>
                신차 구매(리스/장기렌트, 일시불, 할부)부터 내 차 팔기, 자동차
                다이렉트 보험까지 한 번에 끝낼수 있습니다.
              </span>
            </VerticalMoveAnimation>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://play.google.com/store/apps/details?id=com.ksapp.carsayo`}
                >
                  <img
                    src="../imgs/svg/googleIcon.svg"
                    alt="플레이스토어아이콘"
                  />
                  Play Store
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://apps.apple.com/kr/app/카사요-리스-장기렌트-신차구매-중고차팔기-비교견적/id1546852990`}
                >
                  <img src="../imgs/svg/appleIcon.svg" alt="애플스토어아이콘" />
                  App Store
                </a>
              </li>
            </ul>
          </div>
          <div className="right">
            <HorizontalMoveAnimation
              element="div"
              delay={0.2}
              duration={0.5}
              direction="right"
            >
              <div style={{ fontSize: "0" }}>
                <video
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  loop
                  autoPlay
                  muted
                  preload="auto"
                  // controls
                >
                  <source src="../videos/carsayo.mp4" type="video/mp4" />
                </video>
              </div>
            </HorizontalMoveAnimation>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarsayoVisualSection;
