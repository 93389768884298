import { motion } from "framer-motion";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import OpacityAnimation from "../../../utils/opacityAnimation";
import React from "react";

const Introduction02Second = () => {
  return (
    <section>
      <div className="introduction__section02">
        <div className="introduction__section02__content">
          <VerticalMoveAnimation
            className="middle-box"
            element="div"
            direction="top"
            duration={0.5}
          >
            <span className="middle-box__line"></span>
            <p>
              <span>1999년부터 26년간 금융사고 없이</span>
              <span>
                국산차 및 수입차 4대 브랜드에서 최고의 세일즈 기록을 달성하며
              </span>
              <span>
                고객의 안전과 편의를 최우선으로 최적의 조건과 혜택을
                제시했습니다.
              </span>
            </p>
          </VerticalMoveAnimation>
          <OpacityAnimation
            className="bottom-box"
            element="div"
            delay={0.3}
            duration={1}
          >
            <p>
              고객님의 편의를 극대화하기 위해 경험을 바탕으로 다양한 자동차 관련
              서비스를 모은 [카사요]를 출시했습니다.
            </p>
            <p>
              [카사요]는 국내 유일의 자동차 쇼핑 통합 플랫폼으로, 업계
              선도자로서의 자부심과 책임감을 가지고 있습니다.
            </p>
            <p>
              우리는 신뢰할 수 있는 자동차 시장을 위해 자동차 구매, 판매 및
              금융의 고질적인 문제를 해결하고 고객을 보호하기 위해 다양한 장치를
              마련하고 있습니다.
            </p>
            <p>
              ESG(환경, 사회, 지배구조) 경영을 통해 지속 가능한 발전을 추구하며
              투명하고 윤리적인 기업 경영을 실천하고 있습니다.
            </p>
            <p>
              또 소비자중심경영(CCM)을 수행하여 고객과 양방향으로 소통하고,
              지속적인 서비스 개선과 혁신으로 사회적 책임을 다하고 있습니다
            </p>
            <p>
              앞으로도 카사요는 고객의 권익을 보호하고 고객과 신뢰를 쌓아가는
              기업이 될 것을 약속드립니다.
            </p>
            <p>여러분의 지속적인 관심과 성원을 부탁드립니다.</p>
            <strong>감사합니다.</strong>
          </OpacityAnimation>
        </div>
      </div>
    </section>
  );
};
export default Introduction02Second;
