import MainPage from "../pages/main";

// company
import CompanyIndex from "../pages/company/index";
import CompanyIntroduction from "../pages/company/introduction";
import NotFoundPage from "../pages/notFound";
import CompanyOverview from "../pages/company/overview";
import CompanyPhilosophy from "../pages/company/philosophy";
import CompanyLogo from "../pages/company/logo";
import CompanyCharacter from "../pages/company/character";
import ESGManagement from "../pages/esg/esgManagement";
import CCManagement from "../pages/cc/ccManagement";
import ESGIndex from "../pages/esg";
import CCMIndex from "../pages/cc";
import PremiumEvent from "../pages/promotion/PremiumEvent";
import PromotionIndex from "../pages/promotion";
import PromotionPage from "../pages/promotion/promotionPage";
import CharacterMobileSubPage from "../pages/company/character/CharacterMobileSub";

export const routes = [
  {
    path: "/",
    element: <MainPage />,
    nestedRoutes: [{ path: undefined, element: undefined }],
  },
  {
    path: "/promotion/*",
    element: <PromotionIndex />,
    nestedRoutes: [{ path: "/service", element: <PromotionPage /> }],
  },
  {
    path: "/company/*",
    element: <CompanyIndex />,
    nestedRoutes: [
      { path: "/introduction", element: <CompanyIntroduction /> },
      { path: "/overview", element: <CompanyOverview /> },
      { path: "/philosophy", element: <CompanyPhilosophy /> },
      { path: "/logo", element: <CompanyLogo /> },
      { path: "/character", element: <CompanyCharacter /> },
      { path: "/character/detail/:id", element: <CharacterMobileSubPage /> },
    ],
  },
  {
    path: "/esg/*",
    element: <ESGIndex />,
    nestedRoutes: [{ path: "/management", element: <ESGManagement /> }],
  },
  {
    path: "/cc/*",
    element: <CCMIndex />,
    nestedRoutes: [{ path: "/management", element: <CCManagement /> }],
  },
  {
    path: "/*",
    element: <NotFoundPage />,
    nestedRoutes: [{ path: undefined, element: undefined }],
  },
];
