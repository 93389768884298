import { ChangeEvent, InputHTMLAttributes, useRef, useState } from "react";
import _ from "lodash";
import CarsayoButton from "./CarsayoButton";

interface CarsayoFileUploadInterface
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "className" | "label"> {
  label: string;
  text?: string;
  className?: string;
  containerClassName?: string;
  fileData?: File | null;
  setFileData?: (file: File | null) => void;
  inputType?: string; // 이미지랑 pdf 외에 다른 파일 확장자들로 지정하고 싶으면 사용합니다.
}
const CarsayoFileUpload = ({
  label,
  text,
  className,
  containerClassName,
  fileData,
  setFileData,
  inputType,
  ...rest
}: CarsayoFileUploadInterface) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedFileUrls, setSelectedFileUrls] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleChangeEvents = {
    pickFile: async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];
      if (file) {
        try {
          console.info(file);
          const fileUrl = URL.createObjectURL(file);
          setSelectedFile(file);
          setSelectedFileUrl(fileUrl);

          setFileData && setFileData(file);
        } catch (error) {
          console.error("error");
        }
      }
    },
  };

  const handleClickEvents = {
    deleteFile: () => {
      setFileData && setFileData(null);
      setSelectedFile(null);
      setSelectedFileUrl("");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    },
  };

  return (
    <>
      <div
        style={{
          display: selectedFile ? "none" : "block",
          position: "relative",
        }}
      >
        <label htmlFor={label} className={className && className}>
          {text ? text : "업로드"}
        </label>
        <input
          id={label}
          type="file"
          ref={fileInputRef}
          onChange={handleChangeEvents.pickFile}
          accept={
            inputType ? inputType : `image/*, application/pdf, application/zip`
          }
          className={""}
          {...rest}
        />
      </div>
      {selectedFile && (
        <div className={containerClassName}>
          <a href={selectedFileUrl} target="_blank">
            {selectedFile.name}
          </a>
          <CarsayoButton>파일삭제</CarsayoButton>
        </div>
      )}
    </>
  );
};

export default CarsayoFileUpload;
